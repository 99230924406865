import { createBrowserRouter } from 'react-router-dom';

enum SerafFlatRoute {
	ROOT = '/',
	SIGN_IN = '/sign-in',
	MFA_SETUP = '/mfa-require',
	AUTH_CALLBACK = '/auth/callback',
	PROFILE = '/app/profile',
	PERSONAL_INFO = '/app/profile/personal-info',
	YOUR_SERAF = '/app/profile/seraf',
	CHANGE_PASSWORD = '/app/profile/change-password',
	PROFILE_SECURITY = '/app/profile/security',
	DATA_UPLOAD = '/data/upload',
	DATA_ASSIGN = '/data/assign/:type',
	DATA = '/app/data',
	VAULTS = '/app/vaults',
	USERS = '/app/users',
	ROLES_GENERAL = '/app/roles/general',
	ROLES_PERMISSIONS = '/app/roles/permissions',
	ROLES_VAULTS = '/app/roles/vaults',
	CONNECTIONS = '/app/connections',
	CONNECTION_NEW = '/app/connections/new',
	CONNECTION_GDRIVE = '/app/connections/drive/:email/',
	CONNECTION_ONEDRIVE = '/app/connections/onedrive/:sourceId/',
	CONNECTION_GMAIL = '/app/connections/mail',
	CONNECTION_ALANYTICS = '/app/connections/analytics',
	CONNECTION_NOTION = '/app/connections/notion/:sourceId',
	CHAT = '/chat',
	APPEARANCE = '/app/settings/appearance',
	CURRENCY = '/app/settings/currency',
	HISTORY = '/app/history',
	DASHBOARD = '/chat/dashboard',
	TOPIC = '/chat/:topicId',
	TOPIC_DOCUMENT = '/chat/:topicId/d/:documentId',
	TOPIC_CHUNK = '/chat/:topicId/c/:chunkId',
	DOCUMENT_DASHBOARD = '/chat/document/:documentId',
	CHUNK_DASHBOARD = '/chat/chunk/:chunkId',
	TEMPLATES = '/app/templates',
	TEMPLATES_BUILDER = '/app/templates/editor/:templateId?',
	CUSTOM_RULES = '/app/business-rules',
}

export const SerafRoute = {
	...SerafFlatRoute,
	TOPIC: (topicId: string) => SerafFlatRoute.TOPIC.replace(':topicId', topicId),

	TOPIC_DOCUMENT: (topicId: string, documentId: number) =>
		SerafFlatRoute.TOPIC_DOCUMENT.replace(':topicId', topicId).replace(':documentId', String(documentId)),
	TOPIC_CHUNK: (topicId: string, chunkId: number) =>
		SerafFlatRoute.TOPIC_CHUNK.replace(':topicId', topicId).replace(':chunkId', String(chunkId)),

	DOCUMENT_DASHBOARD: (documentId: number) =>
		SerafFlatRoute.DOCUMENT_DASHBOARD.replace(':documentId', String(documentId)),
	CHUNK_DASHBOARD: (chunkId: number) => SerafFlatRoute.CHUNK_DASHBOARD.replace(':chunkId', String(chunkId)),

	DATA_ASSIGN: (type: 'existing' | 'pending' = 'pending') => SerafFlatRoute.DATA_ASSIGN.replace(':type', type),

	TEMPLATES_BUILDER: (templateId?: string) => SerafFlatRoute.TEMPLATES_BUILDER.replace(':templateId', templateId ?? ''),

	CONNECTION_NOTION: (sourceId: string) => SerafFlatRoute.CONNECTION_NOTION.replace(':sourceId', sourceId),
	CONNECTION_ONEDRIVE: (sourceId: string) => SerafFlatRoute.CONNECTION_ONEDRIVE.replace(':sourceId', sourceId),
} as const;

const router = createBrowserRouter([
	{
		path: SerafFlatRoute.SIGN_IN,
		async lazy() {
			const component = await import('@pages/login-form/LoginForm');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.MFA_SETUP,
		async lazy() {
			const component = await import('@pages/login-form/steps/Mfa/RequireMfa');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.AUTH_CALLBACK,
		async lazy() {
			const component = await import('@pages/auth-callback/AuthCallback');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.ROOT,
		async lazy() {
			const component = await import('./Root');
			return { Component: component.default };
		},
		children: [
			{
				path: '/app',
				async lazy() {
					const component = await import('@layout/inner-page/InnerPage');
					return { Component: component.default };
				},
				children: [
					{
						path: SerafFlatRoute.PERSONAL_INFO,
						async lazy() {
							const component = await import('@pages/profile/profile/Profile');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.YOUR_SERAF,
						async lazy() {
							const component = await import('@pages/profile/bot-settings/BotSettings');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.PROFILE_SECURITY,
						async lazy() {
							const component = await import('@pages/profile/security/Security');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CHANGE_PASSWORD,
						async lazy() {
							const component = await import('@pages/profile/change-password/ChangePassword');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.DATA,
						async lazy() {
							const component = await import('@pages/documents/Documents');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.VAULTS,
						async lazy() {
							const component = await import('@pages/vaults/Vaults');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.USERS,
						async lazy() {
							const component = await import('@pages/members/Members');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_GENERAL,
						async lazy() {
							const component = await import('@pages/roles/components/RolesGeneral/RolesGeneral');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_PERMISSIONS,
						async lazy() {
							const component = await import('@pages/roles/components/RolesPermissions/RolesPermissions');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_VAULTS,
						async lazy() {
							const component = await import('@pages/roles/components/RolesVaults/RolesVaults');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.APPEARANCE,
						async lazy() {
							const component = await import('@pages/settings/appearance/Appearance');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CURRENCY,
						async lazy() {
							const component = await import('@pages/settings/currency/Currency');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CONNECTIONS,
						async lazy() {
							const component = await import('@pages/connections/Connections');
							return { Component: component.default };
						},
						children: [
							{
								index: true,
								async lazy() {
									const component = await import('@pages/connections/components/new-connection/NewConnection');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_NEW,
								async lazy() {
									const component = await import('@pages/connections/components/new-connection/NewConnection');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_NOTION,
								async lazy() {
									const component = await import('@pages/connections/components/notion/NotionSelector');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_GDRIVE,
								async lazy() {
									const component = await import('@pages/connections/components/GDrive');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_ONEDRIVE,
								async lazy() {
									const component = await import('@pages/connections/components/OneDrive');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_GMAIL,
								async lazy() {
									const component = await import('@pages/connections/components/GMail');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_ALANYTICS,
								async lazy() {
									const component = await import('@pages/connections/components/GAnalytics');
									return { Component: component.default };
								},
							},
						],
					},
					{
						path: SerafFlatRoute.HISTORY,
						async lazy() {
							const component = await import('@pages/history/History');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TEMPLATES,
						async lazy() {
							const component = await import('@pages/templates/Templates');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TEMPLATES_BUILDER,
						async lazy() {
							const component = await import('@pages/templates/TemplateBuilder');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CUSTOM_RULES,
						async lazy() {
							const component = await import('@pages/custom-rules/CustomRules');
							return { Component: component.default };
						},
					},
				],
			},
			{
				path: SerafFlatRoute.DATA_UPLOAD,
				async lazy() {
					const component = await import('@pages/documents-upload/DocumentsUploadV2');
					return { Component: component.default };
				},
			},
			{
				path: SerafFlatRoute.DATA_ASSIGN,
				async lazy() {
					const component = await import('@pages/assign-chunks/AssignChunks');
					return { Component: component.default };
				},
			},
			{
				path: SerafFlatRoute.CHAT,
				async lazy() {
					const component = await import('@pages/chat-general/ChatGeneral');
					return { Component: component.default };
				},
				children: [
					{
						path: SerafFlatRoute.DASHBOARD,
						async lazy() {
							const component = await import('@layout/dashboard/Dashboard');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOPIC,
						async lazy() {
							const component = await import('@layout/chat/Chat');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOPIC_DOCUMENT,
						async lazy() {
							const component = await import('@layout/chat/Chat');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOPIC_CHUNK,
						async lazy() {
							const component = await import('@layout/chat/Chat');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.DOCUMENT_DASHBOARD,
						async lazy() {
							const component = await import('@layout/dashboard-document/DashboardDoc');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CHUNK_DASHBOARD,
						async lazy() {
							const component = await import('@layout/dashboard-chunk/DashboardDoc');
							return { Component: component.default };
						},
					},
				],
			},
		],
	},
	{
		path: '*',
		async lazy() {
			const component = await import('@pages/not-found/NotFound');
			return { Component: component.default };
		},
	},
]);

export default router;
